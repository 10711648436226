<template>
  <div>
    <div class="headerTitle">
      <div>
        <div class="search-div">
          <span>职能名称：</span
          ><el-input
            style="width: 67%"
            v-model="inpName"
            placeholder="请输入职能名称"
          ></el-input>
        </div>
        <div class="search-div">
          <span>职能编号：</span
          ><el-input
            style="width: 67%"
            v-model="inpNo"
            placeholder="请输入职能编号"
          ></el-input>
        </div>
        <el-button
          icon="el-icon-search"
          style="margin-left: 10px"
          @click="getFunList()"
          ></el-button
        >
      </div>
      <el-button
        icon="el-icon-document-add"
        type="primary"
        @click="showDialog = true"
        >新增</el-button
      >
    </div>
    <el-table :data="functionList" style="width: 100%" :height="elementHeight">
      <el-table-column prop="functionName" label="职能名称" width="280">
      </el-table-column>
      <el-table-column prop="functionNo" label="职能编号"> </el-table-column>
      <el-table-column label="操作" width="200">
        <template slot-scope="scope">
          <i
            style="margin-right: 20px"
            class="el-icon-delete"
            @click="deleteItem(scope.row, scope.$index)"
          ></i>
          <i class="el-icon-edit" @click="editItem(scope.row)"></i>
        </template>
      </el-table-column>
    </el-table>
    <Dialog
      :showDialog="showDialog"
      @handleClose="cancle"
      @handleReqData="addFun"
      :title="isAdd ? '添加职能' : '修改职能'"
      width="300px"
    >
      <div>
        <div class="_Inp">
          <span>职能名称：</span>
          <el-input placeholder="请输入" v-model="functionNanme" />
        </div>
        <div class="_Inp">
          <span>职能编号：</span>
          <el-input placeholder="请输入" v-model="functionNo" />
        </div>
      </div>
    </Dialog>
  </div>
</template>

<script>
import { get, post, put, deleteMethod } from "../../api/http";
import Dialog from "../../components/dialog/Dialog.vue";
export default {
  components: { Dialog },
  data() {
    return {
      elementHeight: 0,
      functionNanme: "",
      functionNo: "",
      functionList: [],
      isAdd: true,
      rowItem: {},
      inpNo: "",
      inpName: "",
      showDialog: false,
    };
  },
  mounted() {
    this.getFunList();
    this.getElementHeight()
  },
  methods: {
    getElementHeight() {
      var clientHeight = window.innerHeight;
      this.$nextTick(() => {
        this.elementHeight = clientHeight - (60 + 90);
      });
    },
    getFunList() {
      var url =
        this.inpNo || this.inpName
          ? "/api/Function/GetByFunction?FunctionNo=" +
            this.inpNo +
            "&FunctionName=" +
            this.inpName
          : "/api/Function/GetList";

      get(url).then((resp) => {
        if (resp.code == 200) {
          this.functionList = resp.data;
        }
      });
    },
    addFun() {
      if (this.functionNanme == "" || this.functionNo == "") return;
      var data = {
        id: this.isAdd ? 0 : this.rowItem.id,
        functionNo: this.functionNo,
        functionName: this.functionNanme,
        isDelete: 0,
        saveTime: this.isAdd ? new Date().toISOString : this.rowItem.saveTime,
        saveUserId: this.$store.state.userInfo.saveUserId,
      };
      var methods = this.isAdd ? post : put;
      methods("/api/Function", data)
        .then((resp) => {
          if (resp.code == 200) {
            this.$message({
              type: "success",
              message: this.isAdd ? "添加成功" : "修改成功",
              duration: 1000,
            });
            this.cancle();
            this.getFunList();
          } else {
            this.$message.error(resp.message);
          }
        })
        .catch(() => {
          this.$message.error(this.isAdd ? "添加失败" : "修改失败");
        });
    },
    deleteItem(row, index) {
      this.$confirm("您确认要删除" + row.functionName + "?")
        .then(() => {
          deleteMethod("/api/Function?id=" + row.id)
            .then((resp) => {
              if (resp.code == 200) {
                this.$message({
                  type: "success",
                  message: "删除成功",
                  duration: 1000,
                });
                this.functionList.splice(index, 1);
              } else {
                this.$message.error(resp.message);
              }
            })
            .catch(() => {
              this.$message.error("删除失败");
            });
        })
        .catch(() => {});
    },
    editItem(row) {
      this.showDialog = true;
      this.isAdd = false;
      this.rowItem = row;
      this.functionNanme = row.functionName;
      this.functionNo = row.functionNo;
    },
    cancle() {
      this.showDialog = false;
      this.isAdd = true;
      this.functionNo = "";
      this.functionNanme = "";
    },
  },
};
</script>

<style lang="less" scoped>
._Inp {
  width: 240px;
  /deep/ .el-input__inner {
    background-color: #edf0f7 !important;
  }
}
.headerTitle {
  display: flex;
  align-items: center;
  margin: 10px 0 15px 0;
  justify-content: space-between;
  > div {
    display: flex;
    align-items: center;
    & .search-div {
      width: 250px;
      display: flex;
      align-items: center;
      > span {
        width: 80px;
        text-align: end;
      }
    }
  }
}

.tow-box {
  display: flex;
  justify-content: center;
  padding-left: 7px;
  > div {
    width: 45%;
    margin-top: 10px;
    padding-left: 25px;
    > p {
      border-left: 5px solid #5a8bff;
      padding-left: 5px;
      margin: 5px 0 15px 0;
    }
    .el-button:nth-of-type(1) {
      margin-left: 70px;
      margin-top: 10px;
    }
    .el-button:nth-of-type(2) {
      margin-left: 10px;
      margin-top: 10px;
    }
  }
  /deep/ .el-table__row > td:nth-of-type(1) {
    background-color: #f6f6f686;
  }
  /deep/ .el-table td.el-table__cell,
  .el-table th.el-table__cell.is-leaf {
    border-bottom: transparent !important;
  }
  .el-table {
    border: 1px solid #c2c2c2;
    & i {
      border-radius: 50%;
      border: 1px solid silver;
      padding: 5px;
      margin: 0 5px;
    }
    & i:nth-of-type(1) {
      background-color: #fef9f9;
      color: red;
      border: 1px solid #d3b2b2;
    }
    & i:nth-of-type(2) {
      background-color: #eff3fa;
      color: #5a8bff;
      border: 1px solid #b2c7d3;
    }
  }
  .el-table--fit {
    border-right: 1px solid #c2c2c2;
    border-bottom: 1px solid #c2c2c2;
  }
}
// /deep/ .has-gutter .el-table__cell:nth-of-type(1),
// /deep/ .el-table__row > .el-table__cell:nth-of-type(1) {
//   border-right: 5px solid #f5f4f7 !important;
//   border-bottom: 0 !important;
// }
// /deep/ .has-gutter .el-table__cell:nth-of-type(2),
// /deep/ .el-table__row > .el-table__cell:nth-of-type(2) {
//   text-align: start !important;
//   padding-left: 50px !important;
// }
</style>
